import React, { useState, useEffect, useRef  } from "react";
import Header from "@Components/Header/Header"
import JobListingPage from "@Components/JobListing/JobListing"
import { Link } from "gatsby"
import { Row, Col, Container, Modal } from 'react-bootstrap';
// import '../Components/JobListing/Technology/Technology.Scss'
import { Button, Card, Accordion, Form } from "react-bootstrap";
import FilterSearch from '@Components/JobListing/FilterSearch/FilterSearch'
import JobAlert from "@Components/JobListing/JobAlert/JobAlert";
import { CANDIDATE_PAGE_URL } from "@Components/common/site/constants";
import FooterPage from "@Components/Footer/Footer"
import $ from "jquery";
import qs from "qs"
import ScrollAnimation from 'react-animate-on-scroll'
import algoliasearch from "algoliasearch/lite"
import { navigate } from "@reach/router"
import { SaveItem } from "@Components/myaccount/wishlistlink"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
    connectRefinementList
} from "react-instantsearch-dom"
import SEO from "@Components/seo";
import { JOBS_PAGE_URL, JOBSA_PAGE_URL, JOBSD_PAGE_URL } from "@Components/common/site/constants";
import Cookies from 'universal-cookie'
import Select from 'react-select';
import './assets/_index.scss'
import { numberWithCommas } from "@Components/common/site/common-functions"
const mycookies = new Cookies();
const updateAfter = 500
const DEBOUNCE_TIME = 500;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""


const searchClient = algoliasearch(
   process.env.GATSBY_ALGOLIA_APP_ID,
   process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const scrollTop = () => {
  window.scrollTo(0, 0);
}
var toogletypecheck = "" 
const toggleCheckbox = (e) => {
  debugger
  toogletypecheck = e
}
// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
const InfiniteHits = ({
   hits,
   hasPrevious,
   refinePrevious,
   hasMore,
   refineNext,
   location,
}) => (
   <>
       <Row>
           <Col lg={12} className="text-center">
           {(hits.length > 0 && hasPrevious) && (
               <button
               id="myprevbut"
               onClick={refinePrevious}
               disabled={!hasPrevious}
               className="btn btn-link text-uppercase text-white d-none"
               >
               Show prev
               </button>
           )}
           </Col>
       </Row>
       {
            hits.map((hit, i) => {
               return (
                  <>
                  {/* <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit.objectID}> */}
                  {/* {JSON.stringify(hit.jobopeningid)} */}
                  <div className="job">
                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                    <h2><Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit.objectID}>{hit.posting_title}</Link></h2>
                    <div className="job-nito">
                    {hit.city && hit.state &&
                    <div className="job-info">
                            <i className="icon-location"> </i>
                        <span>{capitalize(hit.city.replace(/-/g," "))}{hit.city ? ', ' : ''} {hit.state}</span>
                    </div>
                    }
                    {hit.job_type &&
                    <div className="job-info">
                            <i className="icon-clock">
                            </i>
                        <span>{capitalize(hit.job_type.replace(/-/g," "))}</span>
                    </div>
                    }
                    {hit.salary && !hit.salaryNAN &&
                    <div className="job-info">
                            <i className="icon-money">
                            </i>
                        <span>{numberWithCommas(hit.salary)} {hit.currency}</span>
                    </div>
                    }
                     {hit.salaryNAN &&
                    <div className="job-info">
                            <i className="icon-money">
                            </i>
                        <span>{hit.salaryNAN}</span>
                    </div>
                    }
                    </div>
                    {/* <ReactMarkdown source={hit.job_description} allowDangerousHtml /> */}
                    <p className="desc-para">
                      <span dangerouslySetInnerHTML={{ __html: hit.job_description?.replace(/<[^>]+>/g, '').substring(0, 150)}}></span>{hit.job_description?.length > 300 ? <>...
                      <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit.objectID}  className="read-more-btn-job">
                            Read More
                        </Link>
                      </>
                      : ''}
                      </p>
                    <div className="buttons">
                       {/* <ApplyModal job_url={process.env.GATSBY_SITE_URL+"jobs/detail/"+hit.objectID} posting_title={hit.posting_title} /> */}
                       <Link to={JOBSD_PAGE_URL.alias+"/"+hit.slug+'-'+hit.objectID}  className="btn more-info-btn">
                            <i className="icon-cross icon-info-job"> </i>
                            More Info
                        </Link>
                        <Link to={JOBSA_PAGE_URL.alias+"/"+hit.slug+'-'+hit.objectID}  className="btn">
                            <i className="icon-cross icon-cross-job"> </i>
                            quick apply
                        </Link>
                        {/* <a href="#" className="like-us">
                            <i className="icon-like">
                            </i>
                            <span className="sm-text d-none d-md-block">
                                shortlist
                         </span>
                        </a> */}
                        <SaveItem itemId={hit.objectID} itemClass="like-us" />
                    </div>
                </ScrollAnimation>
                </div>
                {/* </Link> */}
                  {/* {i == 2 ? <JobAlert /> : ""} */}
                  </>
                  )
            })
        }
       {(hits.length > 0 && hasMore) && (
          <Col lg={12} className="d-flex justify-content-center py-5">
            <button
              id="mynextbut"
              onClick={refineNext}
              disabled={!hasMore}
              className="primary btn btn-primary property-search-load-more"
            >
                Show more
            </button>
          </Col>
        )}
    </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

// Property list loop

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
   return (
       <>
       {nbHits === 0 &&
               <div className="no-result-blk">No results have been found.</div>
       }
       </>
   )
}

const CustomNoStats = connectStats(NoStats)

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Search box
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {

   return (
    <div className="tab-content tablet d-md-block">
      <div className="tab-form">
        <Form.Control autoComplete="off" id="search" name="search" type="text" placeholder="Job title, position or location" className="input-field job-search-field"
          onChange={event => refine(event.currentTarget.value)}
          value={currentRefinement.replace(/-/g, ' ')}
        />
          <Link className="search">
            <i className="icon-search">
          </i>
        </Link>
      </div>
    </div>
   )
})
// Search box

// Search box
const MobileHeaderSearchBox = connectSearchBox(({ currentRefinement, refine }) => {

  return (
   <div className="tab-content tablet d-md-block mobile-search-header">
     <div className="tab-form">
       <Form.Control autoComplete="off" id="search" name="search" type="text" placeholder="Job title, position or location" className="input-field job-search-field"
         onChange={event => {refine(event.currentTarget.value); window.scrollTo(0, 0);}}
         value={currentRefinement.replace(/-/g, ' ')}
       />
         <Link className="search">
           <i className="icon-search">
         </i>
       </Link>
     </div>
   </div>
  )
})
// Search box

// custom refinementlist
const CustomRefinementList = connectRefinementList(({ items, refine }) => {
  // items.sort((a,b) => (a.label > b.label) ? 1 : (b.label > a.label) ? -1 : 0));
  // console.log(items)
  return(
  <ul className="job-search-left">
    {items.map(item => (
      <li key={item.label}>
        <label>
          <input
            type="checkbox"
            checked={item.isRefined}
            onClick={() => scrollTop()}
            onChange={() => refine(item.value)}
          />
            {
              item.label == "architecture-construction" ? 'Architecture & Construction'+" ("+item.count+")"
              : item.label == "it-services" ? 'IT Services'+" ("+item.count+")"
              : capitalize(item.label.replace(/-/g," "))+" ("+item.count+")"
            }
        </label>
      </li>
    ))}
  </ul>
  )}
);
// custom refinementlist

const CustomRefinementListSalary = connectRefinementList(({ items, refine }) => {
  var listitem = [];
  items.filter(item => item.label !== 'others').map(item => listitem.push(item))

  listitem.sort((a,b) => (parseInt(a.label) < parseInt(b.label)) ? 1 : ((parseInt(b.label) < parseInt(a.label)) ? -1 : 0));
  console.log(items)
  // items.filter(item => item !== 'Others')
  return(
  <ul className="job-search-left">
    {listitem.map(item => (
      <li key={item.label}>
        <label>
          <input
            type="checkbox"
            checked={item.isRefined}
            onClick={() => scrollTop()}
            onChange={() => refine(item.value)}
          />
            {
              item.label == "architecture-construction" ? 'Architecture & Construction'+" ("+item.count+")"
              : item.label == "it-services" ? 'IT Services'+" ("+item.count+")"
              : capitalize(item.label.replace(/-/g," "))+" ("+item.count+")"
            }
        </label>
      </li>
    ))}
    {items.filter(item => item.label === 'others').map(item => (
      <li key={item.label}>
        <label>
          <input
            type="checkbox"
            checked={item.isRefined}
            onClick={() => scrollTop()}
            onChange={() => refine(item.value)}
          />
            {
              item.label == "architecture-construction" ? 'Architecture & Construction'+" ("+item.count+")"
              : item.label == "it-services" ? 'IT Services'+" ("+item.count+")"
              : capitalize(item.label.replace(/-/g," "))+" ("+item.count+")"
            }
        </label>
      </li>
    ))}
  </ul>
  )}
);
// custom refinementlist

// Custom sortby
// Dropdown react select styles
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
          ...styles,
          backgroundColor: isSelected ? "#0c203c" : null,
          color: isSelected ? "#ffffff" : "#000000"
      };
  },
  control: styles => ({ 
      ...styles, 
      backgroundColor: null, 
      border: 0, 
      paddingLeft: 0,
      outline: 0, 
      boxShadow: 'none', 
      color: '#0c203c',
      fontSize: '1.125rem',
      fontWeight:'normal'
  }),
  valueContainer: (styles) => ({
      ...styles,
      fontSize: '1.125rem',
      paddingLeft: 0,
      lineHeight: '30px',
      fontWeight:'normal',
      cursor: 'pointer'
  }),
  dropdownIndicator: (styles) => ({
      ...styles,
      color: "#ffffff",
  }),
  indicatorsContainer: (styles) => ({
      ...styles,
      color: '#ffffff',
      cursor: 'pointer'
  }),
  indicatorSeparator: () => null,
  placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#515862',
          marginLeft: 0
      }
  },
}

const customSalaryStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
          ...styles,
          backgroundColor: isFocused ? "#0c203c" : null,
          color: isFocused ? "#ffffff" : "#000000",
          // controlHeight: 30,
      };
  },
  control: styles => ({ 
      ...styles, 
      backgroundColor: null, 
      border: 1, 
      paddingLeft: 0,
      paddingRight: "25px",
      outline: 0, 
      boxShadow: 'none', 
      color: '#0c203c',
      fontSize: '1.125rem',
      fontWeight:'normal',
      borderBottom: '1px solid #DDE0E7',
      borderRadius: 0
  }),
  valueContainer: (styles) => ({
      ...styles,
      fontSize: '1.125rem',
      paddingLeft: 0,
      lineHeight: '30px',
      fontWeight:'normal',
      cursor: 'pointer'
  }),
  dropdownIndicator: (styles) => ({
      ...styles,
      color: "#ffffff",
  }),
  indicatorsContainer: (styles) => ({
      ...styles,
      color: '#ffffff',
      cursor: 'pointer'
  }),
  indicatorSeparator: () => null,
  placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#515862',
          marginLeft: 0
      }
  },
}

const DownIndicator = () => (
  <>
      <i className={ "icon-filter-dropdown sort-by" }></i>
  </>
)

const SalaryIndicator = () => (
  <>
    <i className="icon-filter-dropdown salary-filter-icon"></i>
  </>
)
// Dropdown react select styles

const CustomSortBy = ({ items, refine, currentRefinement }) => (
  <div className="search-select-box">
    <Select
        options={items}
        defaultValue={currentRefinement || ''}
        placeholder="Most Recent First"
        classNamePrefix={"select-opt"}
        isSearchable={false}
        onChange={(e) => {refine(e.value, e.label)}}
        styles={customStyles}
        components={{ DropdownIndicator: () => <DownIndicator /> }}
    />
  </div>
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Custom sortby

// Salary dropdown
const salaryRange = [
  {
    value: "",
    label: "Salary"
  },
  {
    value: "5000",
    label: "5,000"
  },
  {
    value: "10000",
    label: "10,000"
  },
  {
    value: "15000",
    label: "15,000"
  },
  {
    value: "20000",
    label: "20,000"
  },
  {
    value: "25000",
    label: "25,000"
  },
  {
    value: "30000",
    label: "30,000"
  },
  {
    value: "35000",
    label: "35,000"
  },
  {
    value: "40000",
    label: "40,000"
  },
  {
    value: "45000",
    label: "45,000"
  },
  {
    value: "50000",
    label: "50,000"
  },
  {
    value: "55000",
    label: "55,000"
  },
  {
    value: "60000",
    label: "60,000"
  },
  {
    value: "65000",
    label: "65,000"
  },
  {
    value: "70000",
    label: "70,000"
  },
  {
    value: "75000",
    label: "75,000"
  }
]

const salarySelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let salaryvalselect = ""
  if (currentRefinement.max !== undefined) {
    salaryvalselect = 0
  } else if (currentRefinement.min !== undefined) {
    salaryvalselect = currentRefinement.min
  }
  
  return (
      <Form.Group className="salary-filter">
          <div className="select-group">
              <Select
                  name="salary"
                  options={salaryRange}
                  defaultValue={currentRefinement.min || "0"}
                  classNamePrefix={"select-opt"}
                  placeholder={"Salary"}
                  // onChange={(e) => {
                  //     if (e.value === "") {
                  //         refine(e.value, e.label)
                  //     } else if (e.value === "0") {
                  //         refine({
                  //             max: e.value,
                  //         })
                  //     } else {
                  //         refine({
                  //             min: e.value,
                  //         })
                  //     }
                  // }}
                  onChange={(e) => {
                    if (e.value === "") {
                      refine({
                          min: e.value,
                          max: ""
                      })
                    } else {
                        refine({
                            min: e.value,
                            max: ""
                        })
                    }
                  }}
                  isSearchable={false}
                  styles={customSalaryStyles}
                  components={{ DropdownIndicator: () => <SalaryIndicator /> }}
                  maxMenuHeight={100}
              />
          </div>
      </Form.Group>
  )
}

const CustomsalarySelect = connectRange(salarySelect)
// Salary dropdown

// Salary others
const SalaryOther = ({ items, currentRefinement, refine }) => {

  var optionsArr = [];

  optionsArr = [{label:'All', value: ''}];

  items && (items.length > 0) && items.map((item, index) => {
    optionsArr.push(item);
  })

  return(
    // items && (items.length > 0) ?
    <Form.Group className="salary-filter">
        <div className="select-group">
            <Select
                name=""
                options={optionsArr}
                defaultValue={currentRefinement || ''}
                placeholder={"Salary"}
                classNamePrefix={"select-opt"}
                onChange={(e) => {refine(e.value, e.label)}}
                isSearchable={false}
                styles={customSalaryStyles}
                components={{ DropdownIndicator: () => <SalaryIndicator /> }}
            />
        </div>
    </Form.Group>
    // : null
  )
}

const CustomSalaryOthers = connectMenu(SalaryOther);
// Salary others

// Create URL
const createURL = state => {
    const isDefaultRoute =
      !state.query &&
      state.page === 1 &&
      (state.refinementList && !state.refinementList?.salary?.length === 0) &&
      (state.refinementList && !state.refinementList?.salaryNAN?.length === 0) &&
      (state.refinementList && !state.refinementList?.industry?.length === 0) &&
      (state.refinementList && !state.refinementList?.city?.length === 0) &&
      (state.refinementList && !state.refinementList?.job_type?.length === 0)
  
    if (isDefaultRoute) {
      return '';
    }

    var areaPath = ""
    var pagePath = ""
    var industryPath = ""
    var cityPath = ""
    var jobtypePath = ""
    var jobPath = ""
    var salaryPath = ""
  
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }

    // if (state.query) {
    //   jobPath = "job-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    // }

    const queryParameters = {};

    if (state.page !== 1) {
      pagePath = `page-${state.page}/`
    }
    if (state.refinementList) {
      if (state.refinementList.industry) {
        industryPath = `industry-${state.refinementList.industry.map(encodeURIComponent).join('-and-')}/`
      }
      if (state.refinementList.city) {
        cityPath = `city-${state.refinementList.city.map(encodeURIComponent).join('-and-')}/`
      }
      if (state.refinementList.job_type) {
        jobtypePath = `jobtype-${state.refinementList.job_type.map(encodeURIComponent).join('-and-')}/`
      }
      if (state.refinementList.salary) {
        jobtypePath = `with-a-salary-aed${state.refinementList.salary.filter(item => item !== 'others').map(encodeURIComponent).join('-and-aed')}/`
      }
      if (state.refinementList.salaryNAN) {
        jobtypePath = `others-${state.refinementList.salaryNAN.map(encodeURIComponent).join('-and-')}/`
      }
    }

    // Salary path
    // if (state.range) {
    //   if (state.range.salary) {
    //     if (state.range.salary.min) {
    //       salaryPath = "with-a-salary-"+state.range.salary.min+"-and-above/"
    //     }
    //   }
    // }
    // Salary path

    // if (state.refinementList?.industry) {
    //   industryPath = `industry-${state.refinementList.industry.map(encodeURIComponent).join('-and-')}/`
    // }
    // if (state.refinementList?.city) {
    //   cityPath = `city-${state.refinementList.city.map(encodeURIComponent).join('-and-')}/`
    // }
    // if (state.refinementList?.job_type) {
    //   jobtypePath = `jobtype-${state.refinementList.job_type.map(encodeURIComponent).join('-and-')}/`
    // }
  
    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    });

    // let myUrl = `${areaPath}${industryPath}${cityPath}${jobtypePath}${queryString}${pagePath}`
    let myUrl = `${industryPath}${cityPath}${jobtypePath}${salaryPath}${queryString}${pagePath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
      myUrl = myUrl.substr(0, myUrl.length - 1)
  
    return `/for-candidates/job-search/${myUrl}`;
  };
// Create URL

// URL to searchstate
const searchStateToUrl = (searchState) =>
  searchState ? createURL(searchState) : '';

// const searchStateToUrl = (props, searchState) =>
//   searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/for-candidates/job-search")

    var areaVal = ""
    var industryVal = ""
    var cityVal = ""
    var jobVal = ""
    var jobtypeVal = ""
    var pageVal = ""
    var sortVal = ""
    var sortVal_filt = ""
    var salaryVal = ""
    var salaryNANVal = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for industry
        if (typeof pathUri[vi] === "undefined") {
          continue
        }
        if (pathUri[vi].indexOf("industry-") >= 0) {
          // its industry
          var indusArrfilt = pathUri[vi].replace("industry-", "")
          industryVal = indusArrfilt.split("-and-")
        }
        if (pathUri[vi].indexOf("city-") >= 0) {
          // its city
          var cityArrfilt = pathUri[vi].replace("city-", "")
          cityVal = cityArrfilt.split("-and-")
        }
        if (pathUri[vi].indexOf("jobtype-") >= 0) {
          // its job
          var jobArrfilt = pathUri[vi].replace("jobtype-", "")
          jobtypeVal = jobArrfilt.split("-and-")
        }
        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }
        // Job title
        if (pathUri[vi].indexOf("job-") >= 0) {
          jobVal = pathUri[vi].replace("job-", "")
        }
        //sortby
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          // its sortby
          sortVal_filt = pathUri[vi].replace("sortby-", "")
          if(sortVal_filt === "price-desc") {
            sortVal = "price_DEV_desc"
          }
          if(sortVal_filt === "bedroom-desc") {
            sortVal = "bedroom_DEV_asc"
          }
        }
        // Salary
        if (pathUri[vi].indexOf("with-a-salary-aed") >= 0) {
          var salaryArrfilt = pathUri[vi].replace("with-a-salary-aed", "").replace(/aed/g, "")
          salaryVal = salaryArrfilt.split("-and-")
        }
        if (pathUri[vi].indexOf("others-") >= 0) {
          var salaryNANArrfilt = pathUri[vi].replace("others-", "")
          salaryNANVal = salaryNANArrfilt.split("-and-")          
        }

        // if (pathUri[vi].indexOf("studio") >= 0) {
        //     // its studio bedroom
        //     bedVal = 0
        // }
        // Salary
        // page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }
  /*console.log("Industry:"+industryVal)
  console.log("City:"+cityVal)
  console.log("Job:"+jobVal)
  console.log("Page:"+pageVal)
  console.log("Sort:"+sortVal)*/

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal == "dubai") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Job title
  if (jobVal) {
    jobVal = jobVal.toLowerCase();
    if (jobVal == "dubai") query += `&query=`
    else query += `&query=` + jobVal
  }

  // Industry
  if (industryVal) {
    for(let v=0; v <= industryVal.length; v++){
        if (typeof industryVal[v] !== "undefined") {
            query += `&refinementList[industry]=` + industryVal[v]
        }
    }
  }

  // City
  if (cityVal) {
    for(let v=0; v <= cityVal.length; v++){
        if (typeof cityVal[v] !== "undefined") {
            query += `&refinementList[city]=` + cityVal[v]
        }
    }
  }

  // Job type
  if (jobtypeVal) {
    for(let v=0; v <= jobtypeVal.length; v++){
        if (typeof jobtypeVal[v] !== "undefined") {
            query += `&refinementList[job_type]=` + jobtypeVal[v]
        }
    }
  }

  // Salary
  if (salaryVal) {
    for(let v=0; v <= salaryVal.length; v++){
        if (typeof salaryVal[v] !== "undefined") {
            query += `&refinementList[salary]=` + salaryVal[v]
        }
    }
  }
  if (salaryNANVal) {
    for(let v=0; v <= salaryNANVal.length; v++){
        if (typeof salaryNANVal[v] !== "undefined") {
            query += `&refinementList[salaryNAN]=` + decodeURIComponent(salaryNANVal[v])
        }
    }
  }
  // if (salaryVal) {
  //   query += `&range[salary][min]=` + salaryVal
  // } 

  // Page
  if (pageVal){
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
};
// URL to searchstate

const JobListing = ({ location, history }, props) => {
  const scrollToTop = () => {
   myRefI.current.scrollIntoView({
        behavior: "smooth"
    })
  };
  const myRefI = useRef(null)
  const myRefL = useRef(null)
  const myRefC = useRef(null)
  const myRefS = useRef(null)

  const executeScrolli = () => {
    myRefI.current.scrollIntoView({
        behavior: "smooth"
    })
}

const executeScrolll = () => {
  debugger
  myRefL.current.scrollIntoView({
      behavior: "smooth"
  })
}

const executeScrollc = () => {
  myRefC.current.scrollIntoView({
      behavior: "smooth"
  })
}

const executeScrolls = () => {
  myRefS.current.scrollIntoView({
      behavior: "smooth"
  })
}


   const [show, setShow] = useState(false);
   let shareurl = '';
   if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : '';
  }
   const showFilter =()=>{
       setShow(!show);
       
       if (typeof window !== "undefined") {
        shareurl = window.location.href ? window.location.href : '';
    }
    sessionStorage.setItem('url', shareurl)

   }
   
   const closeFilter =()=>{
      setShow(false)
      
  }
  const cancelFilter =()=>{
    setShow(false)
    if(sessionStorage.getItem('url') !== window.location.href){
      window.open(sessionStorage.getItem('url'), "_self")
    }
    
}
const [scroll, setScroll] = useState(false);
    
    useEffect(() => {
      $(document).ready(function(){
        $("html, body").scrollTop(0);
      });
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 60)
      })
    }, [])
   
    // 
    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const [debouncedSetState, setDebouncedSetState] = useState(null);

    useEffect(() => {
      if (!location) {
        setSearchState({ searchState: urlToSearchState(location) })
      }

      $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
    });

    const onSearchStateChange = updatedSearchState => {
        clearTimeout(debouncedSetState);

        setDebouncedSetState(
          setTimeout(() => {
              navigate(searchStateToUrl(updatedSearchState), updatedSearchState);
          }, DEBOUNCE_TIME)
        );

        setSearchState(updatedSearchState);
    };  
    // 

   return (
      <InstantSearch
        indexName={index_name}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        routing="true"
      >
      <Configure 
        hitsPerPage={10}
        filters={`publish:true`} 
      />
        <div className="wrapper slide">
          <div className="joblisting-page-header jobdetails-header job-search-header-block"><Header  nobanner={true} /></div>
          <div className={scroll ? "fix-seearch header-search header-search-mobile job-search-form mobilehead" : "header-search header-search-mobile job-search-form mobilehead"}>
            <MobileHeaderSearchBox />
          </div>
            <section className="job-listing-page">
            <SEO title={
                                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                                          searchState?.refinementList?.job_type?.map((node, index) => (
                                            capitalize(node.replace(/-/g, ' '))
                                          ))
                                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                                            ))
                                            +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                                              capitalize(node.replace(/-/g, ' '))
                                            ))
                                            +" "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                                              capitalize(node.replace(/-/g, ' '))
                                            ))
                                            :
                                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                                        searchState?.refinementList?.job_type?.map((node, index) => (
                                          capitalize(node.replace(/-/g, ' '))
                                        ))
                                          +" "+searchState?.refinementList?.industry?.map((node, index) => (
                                            capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                                          ))
                                          +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                                            capitalize(node.replace(/-/g, ' '))
                                          ))
                                          +" "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                                            Number.isInteger(parseInt(node)) ? Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null : null
                                          ))
                                          :              
              searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            ))+ " across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+ " across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            "Latest Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                          +" "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                            Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                          )) + " across the UAE"
                        :
                        searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                        "Latdescrest Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                          capitalize(node.replace(/-/g, ' '))
                        ))
                      +" "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                        capitalize(node.replace(/-/g, ' '))
                      )) + " across the UAE"
                    :

                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            )) + " across the UAE"
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                            searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            )) + " across the UAE"
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ? 
                            "Latest "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))+" Jobs Across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Technology, Financial Services & Marketing Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 ? 
                            "Latest "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs Across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ?
                            "Technology, Financial Services & Marketing Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          )) 
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" Technology, Financial Services & Marketing Jobs Across the UAE"
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                          "Latest Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                            
                            Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                          ))
                          +" across the UAE"
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                          "Latest Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" across the UAE"
                          :
                            "Technology, Financial Services & Marketing Jobs Across the UAE"
                          } 


                          description={
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                            "Check the latest "+searchState?.refinementList?.job_type?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                              +" jobs in "+searchState?.refinementList?.industry?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                              ))
                              +" industry "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                                Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                              ))
                              +" at "+searchState?.refinementList?.city?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                              ))+ " with Allsopp & Allsopp Recruitment."
                              :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            "Check the latest "+searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                            +" jobs in "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" industry "+searchState?.refinementList?.salary.filter(item => item !== 'others')?.map((node, index) => (
                              Number.isInteger(parseInt(node)) ? capitalize(node.replace(/-/g, ' '))+ ' AED' : null
                            ))
                            +" at "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))+ " with Allsopp & Allsopp Recruitment."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          "Check the latest "+searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                            +" obs in "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" industry at "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+" with Allsopp & Allsopp Recruitment."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              "Find the best "+searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Technology, Financial Services & Marketing jobs for you "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            ))+ " through Allsopp & Allsopp Recruitment."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                            "Find the best "+searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Technology, Financial Services & Marketing jobs for you "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+ " through Allsopp & Allsopp Recruitment."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            "Browse the latest jobs in Technology, Financial Services & Marketing "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            ))
                          +" in "+searchState?.refinementList?.city?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          )) + " with Allsopp & Allsopp Recruitment."
                        :
                        searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                        "Browse the latest jobs in Technology, Financial Services & Marketing "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                          capitalize(node.replace(/-/g, ' '))
                        ))
                      +" in "+searchState?.refinementList?.city?.map((node, index) => (
                        capitalize(node.replace(/-/g, ' '))
                      )) + " with Allsopp & Allsopp Recruitment."
                    :

                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            "Explore the latest "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            )) + " through Allsopp & Allsopp Recruitment, the leading recruitment agency in Dubai."
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                            "Explore the latest "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            )) + " through Allsopp & Allsopp Recruitment, the leading recruitment agency in Dubai."
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ? 
                            "Browse through the current job openings in "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" at "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+"  with Allsopp & Allsopp Recruitment. Quick Apply for the job that matches your profile."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                            "Find the latest "+
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" jobs in "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))+" industry with Allsopp & Allsopp Recruitment, the leading recruitment agency in Dubai."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              "Looking for "+searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Technology, Financial Services & Marketing jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+" ? Apply for a suitable job with Allsopp & Allsopp Recruitment."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 ? 
                            "Find the latest "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" jobs through Allsopp & Allsopp Recruitment, the leading recruitment agency in Dubai."
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ?
                            "Explore the latest job opportunities in Technology, Financial Services & Marketing in "+searchState?.refinementList?.city?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          )) + " with Allsopp & Allsopp Recruitment."
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          "Searching for  "+searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" Technology, Financial Services & Marketing jobs? Discover the suitable position for you with Allsopp & Allsopp Recruitment."
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                          "Looking for a job "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                            Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                          ))
                          +" We know how stressful job searching can be and we've got your back. Contact us for help."
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                          "Looking for a job "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" We know how stressful job searching can be and we've got your back. Contact us for help."
                            :
                            "Discover the latest Technology, Financial Services & Marketing Jobs across the UAE with Allsopp & Allsopp Recruitment, the leading recruitment agency in Dubai."
                            } 
                            
                            location={shareurl}
                            />


                            
                          <h1
                           style={{display:"none"}}
                          >{
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                            searchState?.refinementList?.job_type?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                              +" "+searchState?.refinementList?.industry?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                              ))
                              +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                              +" "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                              :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                          searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                            +" "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            ))
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            ))+ " across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))+ " across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            "Latest Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                          +" "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                            Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                          )) + " across the UAE"
                        :
                        searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                        "Latest Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                          capitalize(node.replace(/-/g, ' '))
                        ))
                      +" "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                        capitalize(node.replace(/-/g, ' '))
                      )) + " across the UAE"
                    :

                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                            searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                              Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                            )) + " across the UAE"
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                            searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))
                            +" Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            )) + " across the UAE"
                            :  
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ? 
                          "Latest "+searchState?.refinementList?.industry?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                          ))
                          +" Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                            
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" "+searchState?.refinementList?.industry?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                            ))+" Jobs Across the UAE"
                            :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 && searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                              searchState?.refinementList?.job_type?.map((node, index) => (
                                capitalize(node.replace(/-/g, ' '))
                              ))
                            +" Technology, Financial Services & Marketing Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                              capitalize(node.replace(/-/g, ' '))
                            ))
                          :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 ? 
                          "Latest "+searchState?.refinementList?.industry?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))
                          ))
                          +" Jobs Across the UAE"
                          :
                            searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ?
                            "Technology, Financial Services & Marketing Jobs in "+searchState?.refinementList?.city?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          )) 
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.job_type !== 'string' && searchState?.refinementList?.job_type?.length > 0 ? 
                          searchState?.refinementList?.job_type?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" Technology, Financial Services & Marketing Jobs Across the UAE"
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                          "Latest Jobs "+searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                            Number.isInteger(parseInt(node)) ? (index === 0 ? 'with '+node.replace(/-/g, ' ') : '' +node.replace(/-/g, ' '))+ ' AED' : null
                          ))
                          +" across the UAE"
                          :
                          searchState && searchState?.refinementList && typeof searchState?.refinementList?.salaryNAN !== 'string' && searchState?.refinementList?.salaryNAN?.length > 0 ? 
                          "Latest Jobs "+searchState?.refinementList?.salaryNAN?.map((node, index) => (
                            capitalize(node.replace(/-/g, ' '))
                          ))
                          +" across the UAE"
                          :
                            "Technology, Financial Services & Marketing Jobs Across the UAE"
                          }</h1>


              <div className="mobile-filter"></div>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="technology">
                        {/* <FilterSearch closeFilter={closeFilter} show={show}/> */}
                        <div className={show ? "filter-search" : "hide"}>
            <h2 className="d-lg-none">Filter Your Search</h2>
            <i onClick={closeFilter} className="icon-close">

            </i>
            
            {/* <Form className="tab-form d-md-none">
                                            <Form.Control type="Text" placeholder="Job title, position or location" />
                                            <Link href="#" className="search">
                                                <i className="icon-search">
                                                </i>
                                            </Link>
                                        </Form> */}
                                        <div className="popup-search">
                                        <SearchBox />
                                        </div>
                                        <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle   as={Card.Header} eventKey="0">
                    Industry
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                        
                            <CustomRefinementList 
                                    attribute="industry"
                                    limit={25}
                                    // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1">
                    Locations
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                        
                            <CustomRefinementList 
                                    attribute="city"
                                    limit={25}
                                    // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                    <Accordion.Toggle   as={Card.Header} eventKey="2">
                    Type of Contract
                       
                  </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                        
                              <CustomRefinementList 
                                attribute="job_type"
                                limit={25}
                                // searchable
                              />
                            </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    <Card>
                      <Accordion.Toggle 
                      // disabled={toogletypecheck === 'salaryNAN'}
                        as={Card.Header} eventKey="3">Salary</Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            {/* <CustomsalarySelect 
                              attribute="salary"
                            />
                            <CustomSalaryOthers 
                              attribute="salaryNAN"
                            /> */}
                            <CustomRefinementListSalary 
                                    attribute="salary"
                                    limit={25}
                                    // searchable
                                    // onClick={() => toggleCheckbox('salary')}
                              />
                              {/* <Accordion className="other-toggle">
                            <Card>
                      <Accordion.Toggle 
                        as={Card.Header} eventKey="4">Others</Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                          <Card.Body>
                              <CustomRefinementList 
                                    attribute="salaryNAN"
                                    limit={25}
                              />
                          </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                    </Card>
                    </Accordion> */}
                          </Card.Body>
                        </Accordion.Collapse>
                        <i className="icon-menu"></i>
                        
                    </Card>
                    
                    
                    </Accordion>

                    <div className="clear-filter clear-filter-mobile">
                      <a href="/for-candidates/job-search/">Clear Filter</a>
                    </div>
                <div className="btn-wrapper d-lg-none">
                    <a className="btn cancel" onClick={cancelFilter}>
                        cancel
                   </a>
                    <a className="btn btn-primary save" onClick={closeFilter}>
                        apply filters
                   </a>
                </div>
        </div>
        
                          <span className="sm-content top-bradcrumb d-none d-md-block">
                            <Link to={'/'}>ALLSOPP & ALLSOPP RECRUITMENT</Link> / FIND A JOB
                          </span>
                          {/* {JSON.stringify(searchState?.refinementList)} */}
                          <h2>{searchState && searchState?.refinementList && typeof searchState?.refinementList?.industry !== 'string' && searchState?.refinementList?.industry?.length > 0 ?

                          <span>
                          
                          {searchState?.refinementList?.industry?.map((node, index) => (
                            <><span>{capitalize(node.replace(/-/g, ' ').replace('it ', 'IT ').replace('architecture construction', 'Architecture & construction'))}</span>{searchState?.refinementList?.industry?.length === 1 ? <>&nbsp;</> : searchState?.refinementList?.industry?.length === index+1 ? <>&nbsp;</> : ', '}</>
                          ))}
                          </span>
                        : searchState?.refinementList?.industry === undefined ? '' : capitalize(searchState?.refinementList?.industry?.replace('-', ' ').replace('architecture construction', 'Architecture & construction')) + ' '
                        }
                        {/* capitalize(searchState?.refinementList?.industry.toString()) */}
                         Jobs
                         {/* {<CustomStats /> === "1" ? '' : <CustomStats /> === "0" ? '' : 's'} */}
                         <>&nbsp;</>
                         
                         {searchState && searchState?.refinementList && typeof searchState?.refinementList?.salary !== 'string' && searchState?.refinementList?.salary?.length > 0 ? 
                         <span>
                         {searchState?.refinementList?.salary?.filter(item => item !== 'others').map((node, index) => (
                           <>
                            {index === 0 ? <>with &nbsp;</> :  <></>}
                            {node !== "others" ?<><span>{capitalize(node.replace(/-/g, ' ')) + ' AED'}</span>{searchState?.refinementList?.salary?.length === 1 ? <>&nbsp;</> : searchState?.refinementList?.salary?.length === index+1 ? <>&nbsp;</> : ', '}</> : <>Good Salary & Other Perks </>}
                            </>
                          ))}
                          {searchState?.refinementList?.salary?.filter(item => item === 'others').map((node, index) => (
                           <>
                            {searchState?.refinementList?.salary?.length === 1 ? <>with &nbsp;</> :  <></>}
                            {node !== "others" ?<><span>{capitalize(node.replace(/-/g, ' ')) + ' AED'}</span>{searchState?.refinementList?.salary?.length === 1 ? <>&nbsp;</> : searchState?.refinementList?.salary?.length === index+1 ? <>&nbsp;</> : ', '}</> : <>Good Salary & Other Perks </>}
                            </>
                          ))}
                          </span>
                          : ""}
                         
                         
                         {
                         searchState && searchState?.refinementList && typeof searchState?.refinementList?.city !== 'string' && searchState?.refinementList?.city?.length > 0 ?
                          <span>
                          in<>&nbsp;</> 
                          {searchState?.refinementList?.city?.map((node, index) => (
                            <><span>{capitalize(node.replace(/-/g, ' '))}</span>{searchState?.refinementList?.city?.length === 1 ? <>&nbsp;</> : searchState?.refinementList?.city?.length === index+1 ? <>&nbsp;</> : ', '}</>
                          ))}
                          </span> 
                          // :searchState?.refinementList?.city === undefined ? 'in UAE' 
                          :
                          // searchState?.refinementList?.city?.map((node, index) => (
                          //   searchState?.refinementList?.city?.length === index+1 ? <>&nbsp;</> : ', '
                          //   ))
                          // capitalize(searchState?.refinementList?.city?.replace('-', ' '))
                          'in UAE'
                         }
                          &nbsp;(<CustomStats />)
                         </h2>
                          {/* {JSON.stringify(searchState)} */}


                          <div className="about-technology">
                              <div className="content">
                                  <p>Find the latest jobs.</p>
                              </div>
                              <div className="job-alert">
                                <div className="sort-by">
                                  <span className="sort-list">Sort by:</span>
                                  <span>
                                    <CustomSortByFilter 
                                      defaultRefinement={index_name}
                                      className="search-sory-by"
                                      items={[
                                          //{ value: index_name+"_most_recent", label: "Most Recent First" },
                                          { value: index_name, label: "Most Recent First" },
                                          { value: index_name+"_most_oldest", label: "Most Oldest" },
                                      ]}
                                    />
                                  </span>
                                </div>
                              </div>
                          </div>
                          <div className="filter d-lg-none">
                            <a onClick={showFilter} className="btn">
                              <i className="icon-slider"></i>
                              filter results
                            </a>
                          </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} xl={3} className=" d-none d-lg-block p-xl-0">
                      <div className="desktop-filter">
                        <div className={props.show ? "filter-search" : "hide"}>
                          <h2 className="d-lg-none">Filter Your Search</h2>
                          <i onClick={props.closeFilter} className="icon-close"></i>

                          <Accordion defaultActiveKey="0">
                            <Card>
                              <Accordion.Toggle   as={Card.Header} eventKey="0" onClick={() => scrollToTop()}><div ref={myRefI} id="scroll_view"></div>Industry</Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <CustomRefinementList  
                                    attribute="industry"
                                    limit={25}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                              <i className="icon-menu"></i>
                            </Card>
                            <Card>
                              <Accordion.Toggle   as={Card.Header} eventKey="1" onClick={() => scrollToTop()}><div ref={myRefL} id="scroll_view"></div>Locations</Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                  <Card.Body>
                                    <CustomRefinementList  
                                      attribute="city" 
                                      limit={25}
                                    />
                                  </Card.Body>
                                </Accordion.Collapse>
                                <i className="icon-menu"></i>
                            </Card>
                            <Card>
                              <Accordion.Toggle   as={Card.Header} eventKey="2" onClick={() => scrollToTop()}><div ref={myRefC} id="scroll_view"></div>Type of Contract</Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                  <Card.Body>
                                    <CustomRefinementList  
                                      attribute="job_type" 
                                      limit={25}
                                    />
                                  </Card.Body>
                                </Accordion.Collapse>
                                <i className="icon-menu"></i>
                            </Card>
                            <Card>
                              <Accordion.Toggle 
                              // disabled={toogletypecheck === 'salaryNAN'}
                                as={Card.Header} eventKey="3" onClick={() => scrollToTop()}><div ref={myRefS} id="scroll_view"></div>Salary</Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                  <Card.Body>
                                    {/* <CustomsalarySelect 
                                      attribute="salary"
                                    />
                                    <CustomSalaryOthers 
                                      attribute="salaryNAN"
                                    /> */}
                                    <CustomRefinementListSalary 
                                    attribute="salary"
                                    limit={25}
                                    // onClick={() => toggleCheckbox('salary')}
                                    // searchable
                                    />
                                    {/* <Accordion className="other-toggle">
                                    <Card>
                              <Accordion.Toggle 
                                as={Card.Header} eventKey="4">Others</Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                  <Card.Body>
                                    <CustomRefinementList 
                                          attribute="salaryNAN"
                                          limit={25}
                                    />
                                  </Card.Body>
                                </Accordion.Collapse>
                                <i className="icon-menu"></i>
                            </Card>
                            </Accordion> */}
                                  </Card.Body>
                                </Accordion.Collapse>
                                <i className="icon-menu"></i>
                                
                            </Card>
                            
                          </Accordion>

                          <div className="clear-filter">
                            <a href="/for-candidates/job-search/">Clear Filter</a>
                          </div>

                          <div className="btn-wrapper d-md-none">
                            <a className="btn cancel" href="#">
                                cancel
                            </a>
                            <a className="btn btn-primary save" href="#">
                                apply filters
                            </a>
                          </div>
                        </div>
                      </div>            
                    </Col>
                    
                    <Col lg={8} xl={9} className="ml-auto">
                      <div className="d-none">
                        {/* <RefinementList
                          attribute="publish"
                          defaultRefinement={["true"]}
                        /> */}
                      </div>
                                                      
                      <CustomInfiniteHits />
                      <CustomNoStats />

                    </Col>
                  </Row>
                </Container>
            </section>
          <FooterPage />
        </div>
      
      </InstantSearch>
   )
};
export default JobListing